@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
* {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

body {
  width: 100%;
  background: #f7f6f6;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

body .custom-calender input {
  width: calc(100% - 34px) !important;
}

body .custom-calender {
  display: block !important;
  width: 100% !important;
}
.loader-small {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  overflow: hidden;
  position: fixed;
  left: 127px;
  right: 0;
  z-index: 9;
  margin-top: 59px;
}
.loader-small img {
  width: 100%;
  height: auto;
}
.custom-center {
  text-align: center;
}

.popup-modal {
  width: 50%;
}

.login-wraper {
  background: #f8f8fb;
  padding-top: 45px;
  margin: 0 8px;
}

.customModal {
  position: absolute;
  z-index: 99;
  width: 60%;
  top: 30%;
}

.custom-cal {
  width: 120px;
}
.loading {
  text-align: center;
}
body .search-space[type="search"] {
  padding: 3px !important;
}
.loading img {
  width: 120px;
}
.custom-cal input {
  width: 100%;
}

body .p-dialog .p-dialog-content {
  overflow-x: hidden !important;
}

.address-block {
  background: #fff;
  padding: 12px;
  border: 1px solid #ccc;
  margin-bottom: 12px;
  border-radius: 4px;
}

.dispatch-label label {
  font-weight: normal;
}

.block {
  margin-top: 22px;
  background: #fff;
  padding: 1px 28px 14px;
  border-radius: 14px;
}

body .p-button.p-button-icon-only .p-button-text,
body .p-button.p-button-text-only .p-button-text {
  padding: 2px !important;
}
.sidebar-wraper {
  background:  #129e02;
}
.sidebar-wraper .p-menubar {
  background: transparent;
  border: none;
}
.sidebar-wraper .p-menubar .p-menubar-button {
  display: none;
}
body .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.229em 0.857em !important;
}

.block label {
  font-weight: normal;
}

.product-pic {
  width: 80%;
}

.block h2 {
  color: #df2133;
  position: relative;
}

.redeem-form {
  display: table;
  margin: 30px auto 0;
}

.pa-bg {
  background: url("./assets/images/bg.jpg") no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  width: 100%;
  overflow-y: auto;
}

.block h2::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50px;
  height: 2px;
  background: #f9832a;
  bottom: -7px;
}

.login-container {
  max-width: 520px;
  margin: 30px auto 0;
  padding: 0;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.tree-stucture {
  padding-left: 30px;
  margin-bottom: 8px;
  margin-top: 8px;
  list-style: none;
}

.row1 {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 60px;
}
.p-datatable table {
  margin-bottom: 30px;
}
.dodisable {
  pointerevents: "none";
}

.tree-stucture input {
  margin-right: 12px;
}

.login-logo {
  padding: 30px 24px;
  background: #129e02 url(./assets/images/profile-img.png) no-repeat right
    center;
  color: #fff;
  background-size: contain;
}
.logo {
  height: 145px;
  margin: 0 auto;
  max-width: 700px;
  background: url(./assets/images/logo.png) no-repeat center center;
  background-size: contain;
}
.white-box {
  background: #fff;
  padding: 12px;
}

.scroll-content {
  width: 100%;
  overflow: auto;
}

/* body .p-datatable .p-datatable-tbody>tr>td {
    overflow: auto;
} */

#react-sidebar .sidebar-backdrop.show {
  display: none !important;
}

.border-box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 12px;
  margin: 20px 0;
  box-shadow: 0 0 12px #ccc;
  background: #ffffff;
}

label span {
  color: red;
  padding-right: 4px;
}

.profile-frm input {
  margin-bottom: 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.user-menu .nav-link a {
  color: #000;
}
.loader {
  position: fixed;
  margin: auto;
  text-align: center;
  top: 0;
  padding-top: 10%;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9999;
}
.box {
  background: #fff;
  padding: 12px;
  margin: 10px 0;
  border-radius: 4px;
}
.form-padding {
  padding: 10px 20px;
}
body input[type="search"] {
  padding: 3px !important;
}
.custom-tab .p-button {
  padding: 4px 11px;
}
.progress {
  height: 5px;
  margin-bottom: 7px;
}

.progress-bar {
  background-color: #29c352;
}

.box h3 {
  color: #f68430;
}

.box h4 {
  color: #df2133;
}

.form-wraper {
  padding: 32px;
}

.login-container .form-group {
  position: relative;
  margin-bottom: 20px;
}

.login-container .form-group span {
  position: absolute;
  left: 7px;
  top: 0px;
  font-size: 17px;
}

.toast-header {
  display: flex;
  align-items: end;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: none;
  text-align: right;
}

.form-control {
  font-size: 14px;
}
.login-container label {
  text-align: left;
  font-weight: normal;
  display: block;
}
.login-container .form-control {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  box-shadow: none;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.toast {
  background: #ff7d7d;
  color: #fff;
  padding: 12px;
  border-radius: 4px;
  position: absolute;
  top: 9px;
  right: 8px;
}

.login-container .form-wraper button[type="submit"] {
  width: 100%;
  border-radius: 5px;
  background: #373435;
  border: none;
  padding: 10px;
  margin-bottom: 12px;
  color: #fff;
}
.login-container .form-wraper button:active,
.login-container .form-wraper button:hover,
.login-container .form-wraper button:focus {
  background: #129e02;;
  outline: none;
}
.menu-bar .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  margin-right: 40px;
}
.full-width {
  width: 100%;
  padding: 0 35px;
}
.widget-tasks .task {
  padding: 1rem 0;
}
.widget-tasks .task-name {
  margin-bottom: 0.5rem;
}
.widget-tasks .task-name span {
  font-weight: 600;
}
.widget-tasks .task-progress {
  border-radius: 4px;
  height: 0.5rem;
  background: #dee2e6;
}
.widget-tasks .task-1 .task-progress > div {
  
  background: #64b5f6;
  width: 75%;
}
.widget-tasks .task-2 .task-progress > div {
  background: #a5d6a7;
  width: 60%;
}
.widget-tasks .task-3 .task-progress > div {
  background: #7986cb;
  width: 80%;
}
.widget-tasks .task-4 .task-progress > div {
  background: #9575cd;
  width: 40%;
}
.widget-tasks .task-5 .task-progress > div {
  background: #4db6ac;
  width: 50%;
}
.widget-tasks .task-progress > div {
  border-radius: 4px;
  height: 100%;
}
.custom-breadcrumb .p-breadcrumb {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  height: 25px;
}
.card-headers {
  font-size: 22px;
}
.widget-overview-box {
  min-height: 6rem;
  position: relative;
  border-left: 4px solid;
}
.widget-overview-box .card-headers {
  font-size: 18px;
  padding: 0 0 8px 8px;
}
.dispatch-label .p-dropdown-label {
  width: 91%;
  display: inline-block;
}
.dispatch-label .p-dropdown-trigger,
.tertiary-filter .p-dropdown-trigger {
  display: inline-block;
}
.tertiary-filter .p-dropdown-label {
  width: 84%;
  display: inline-block;
}
.counts {
  padding-left: 10px;
  font-size: 22px;
  margin: 0;
}
body .MuiFormControl-root {
  margin-bottom: 8px;
  width: 100%;
}
body .MuiFormLabel-root {
  font-size: 14px;
}
.widget-overview-box-1 {
  border-left-color: #64b5f6;
}
.widget-overview-box.widget-overview-box-2 {
  border-left-color: #7986cb;
}
.widget-overview-box.widget-overview-box-3 {
  border-left-color: #4db6ac;
}
.widget-overview-box.widget-overview-box-4 {
  border-left-color: #4dd0e1;
}
.custom-breadcrumb .p-breadcrumb ul {
  float: right;
}
body .p-inputtext {
  font-size: 14px;
}
.header-top {
  background: #fff;
  color: #797979;
  /* box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.3); */
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
}

.logo-section {
  width: 335px;
  display: inline-block;
  padding: 4px 20px 4px 0;
  vertical-align: top;
}
.menu-bar
  .p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon,
.menu-bar
  .p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text,
.menu-bar
  .p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: #fff;
}
.menu-bar .p-menubar .p-submenu-list {
  width: 220px;
}
.menu-bar {
  padding: 0;
  margin-top: 80px;
  margin-bottom: 30px;
}
.logo-section img {
  height: 75px;
}
body .p-dropdown .p-dropdown-label {
  padding-right: 2px !important;
  padding-left: 5px !important;
}
body .p-link,
body .p-paginator .pi {
  font-size: 14px;
}
.user-menu {
  display: none;
  position: absolute;
  text-align: left;
  width: 200px;
  color: #000;
  background: #fff;
  right: 0;
  z-index: 9;
  margin-top: 16px;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.user-menu .nav-link:hover,
.user-menu .nav-link:hover a {
  background: #eee;
  text-decoration: none;
}
.user-area .dropdown-toggle::after {
  display: inline-block;
  margin-right: 4px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.right-section {
  display: inline-block;
  width: calc(100% - 335px);
  text-align: right;
  padding: 15px 15px 0 20px;
  margin-bottom: 0;
}

.right-section img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}
.heading h1 {
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
  letter-spacing: 2px;
}
body .p-dialog-resizable .p-dialog-header {
  cursor: move;
  border-bottom: 1px solid #ccc;
  background:#129e02;
  color: #fff;
}
body .p-dialog .p-dialog-header .p-dialog-title {
  font-weight: normal;
  font-size: 18px;
}
body .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #fff;
}
.footer {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 15px 0;
  font-size: 13px;
  position: fixed;
  width: 100%;
  margin-top: 18px;
  bottom: 0;
}

.rounded-circle {
  border-radius: 50% !important;
}

/* You can add global styles to this file, and also import other style files */

.custom-calender .ui-calendar,
.custom-calender .ui-calendar input {
  width: 100%;
}

.custom-calender .ui-calendar input {
  border: 1px solid #ced4da;
}

body .ui-table .ui-table-tbody > tr > td {
  padding: 2px 0.857em;
}

.btn {
  font-size: 14px;
  padding: 0.25rem 0.7rem;
}

body .scroll-td .ui-table .ui-table-tbody > tr > td {
  overflow: hidden !important;
}

body .btn-primary {
  color: #fff;
  background-color: #373435;
  border-color: #373435;
}
body .btn-primary:hover {
  background-color:#129e02;
  border-color: #96bb38;
}
body .p-button {
  background: #373435;
  border: 1px solid #373435;
}
body .p-button:not(a):not(.p-disabled):hover {
  background: #129e02;
  color: #ffffff;
  border-color: #129e02;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #191919 !important;
  color: #f3c500 !important;
}

.export-disabled[disabled] {
  pointer-events: none;
}

.sidebars {
  background: #129e02;
  top: 0;
  position: relative;
  height: calc(100vh - 124px);
}

.p-datatable-emptymessage {
  text-align: center;
}

body #react-sidebar .sidebar-main {
  width: 260px;
  height: calc(100% - 128px);
  background-color: #df2133;
  top: 79px;
  left: 0;
  overflow-y: auto;
  box-shadow: none;
  z-index: 9;
}

body .sidebar-main-content ul li {
  color: #fff;
}

body .sidebar-main-content ul li:hover {
  background-color: transparent;
  color: #e0e0e0;
  letter-spacing: 2px;
  transition: all 0.3s;
}

body #react-sidebar .sidebar-main.second {
  position: fixed;
  top: 0;
}

body #react-sidebar .second.show {
  top: 0;
}

body .p-panelmenu .p-panelmenu-header > a {
  border: none !important;
  background-color: transparent !important;
  color: #fff !important;
  font-weight: normal !important;
}

body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  color: #fff !important;
  float: right !important;
}

body
  .p-panelmenu
  .p-panelmenu-header:not(.p-highlight)
  > a:hover
  .p-panelmenu-icon {
  color: #fff !important;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a {
  border: none !important;
  background-color: transparent !important;
  color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

body .p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none !important;
}

.error {
  color: red;
  padding-top: 4px;
  font-size: 13px;
}
.timeline {
  position: relative;
  padding: 0;
  counter-reset: section;
  padding-left: 20px;
  transition: all 1.4s;
  overflow-y: hidden;
}
.timeline li:last-child {
  margin-bottom: 0;
}
body .p-inputtext {
  padding: 0 !important;
}
body .p-tabview.p-tabview-top .p-tabview-nav li a {
  padding: 3px 1em !important;
}
body .p-dialog .p-dialog-content {
  padding: 4px !important;
}
.timeline::before {
  background: #129e02;
  content: "";
  position: absolute;
  left: 17px;
  top: 0;
  width: 2px;
  height: 87%;
}
.timeline:empty {
  height: 0px !important;
  overflow: hidden;
}
.timeline li {
  list-style: none;
  padding-left: 24px;
  margin-bottom: 20px;
  position: relative;
}
.timeline li span {
  color: #949292;
  font-size: 13px;
  font-style: italic;
  display: block;
}
.timeline li::before {
  position: absolute;
  left: -17px;
  top: 0;
  width: 30px;
  height: 30px;
  counter-increment: section;
  content: counter(section);
  background: #48bd0b;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
}
body .p-dropdown .p-dropdown-label {
  padding-right: 2.7em !important;
}

.err-mess {
  font-size: 18px;
  color: red;
}

.user-menu .nav-link {
  padding: 6px 12px;
  cursor: pointer;
}

.user-menu .nav-link .fa {
  padding-right: 8px;
}

.ui-panelmenu .ui-menuitem-text {
  padding-left: 12px;
}

.open .sidebar-wraper {
  overflow: visible;
}

body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  border: 1px solid #f9842d !important;
  background-color: #f9842d !important;
  color: #ffffff !important;
}

.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color:  #129e02;;
  border-color: #129e02;;
  box-shadow: none;
}

.custom-mdrop,
.custom-mdrop .ui-multiselect {
  display: block;
  width: 100%;
  margin-bottom: 12px;
}

.custom-drop {
  width: 80%;
}

.custom-drop2 {
  width: 250px;
}

.custom-drop .p-dropdown,
.custom-drop2 .p-dropdown {
  width: 100%;
}

.custom-input {
  font-size: 14px;
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  -webkit-transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: border-color 0.2s, -webkit-box-shadow 0.2s;
  transition: border-color 0.2s, -webkit-box-shadow 0.2s;
  transition: border-color 0.2s, box-shadow 0.2s;
  transition: border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
}

.table-option .ui-dropdown {
  width: 100%;
  border: 1px solid #ced4da;
}

.open .sidebar-wraper {
  width: 6%;
}

.open .sidebar-wraper .fa {
  font-size: 19px;
}

.open .left-panel {
  display: none;
}

.open .right-panel {
  width: 99%;
  margin-left: 15px;
}

.search-field {
  float: right;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 20px;
}

.right-panel {
  margin-left: 276px;
  margin-bottom: 50px;
  margin-top: 94px;
  padding-right: 30px;
  padding-left: 15px;
  width: 80%;
  display: inline-block;
}

.left-panel {
  width: 19%;
  display: inline-block;
  vertical-align: top;
  margin-top: 78px;
  background: #df2133;
  padding: 12px;
  height: 80vh;
  position: fixed;
  left: 0;
}
#react-sidebar .sidebar-main-content {
  width: 100%;
  display: table;
  /* flex-direction: column; */
  /* height: calc(100% - 42px); */
  /* overflow-y: auto; */
}
.sidebar-wraper a {
  color: #fff;
  padding: 0;
  display: block;
}

.sidebar-wraper a .fa {
  margin-right: 12px;
}

#sidebar-parent {
  padding-top: 12px;
}

body .sidebar-body > ul {
  margin-bottom: 12px;
}

.right-panel.resize {
  max-width: 90%;
  flex: 0 0 90%;
}

.right-panel .heading h1 {
  font-weight: normal;
  font-size: 25px;
  color: #129e02;
  display: inline-block;
}

.heading {
  display: table;
  width: 100%;
}

.p-dialog-content label {
  font-weight: normal;
}

body .heading .p-button {
  float: right;
  margin-top: 0;
  margin-bottom: 13px !important;
}

.open .p-panelmenu-header .p-menuitem-text {
  font-size: 0;
}

body .open .p-panelmenu .p-panelmenu-header > a {
  padding: 0.714em 0;
}

.open .p-toggleable-content {
  position: absolute;
  right: -157%;
  margin-top: -30px;
  border-top: 1px solid #ccc;
}

.open .p-toggleable-content-enter-done .p-menuitem-text {
  font-size: 14px;
}

.open .ui-panelmenu .ui-panelmenu-content-wrapper .ui-menuitem-text,
.ui-panelmenu .ui-panelmenu-content-wrapper .ui-menuitem-text {
  font-size: 13px;
}

.open .ui-panelmenu .ui-panelmenu-content-wrapper {
  overflow: hidden;
  position: absolute;
  right: -160%;
  z-index: 999;
}

.custom-drop2 .ui-dropdown {
  width: 100%;
  margin-bottom: 0;
  border: 1px solid #ced4da;
  min-width: 100%;
}

.custom-drop .ui-dropdown {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
}

body .ui-panelmenu .ui-panelmenu-header > a {
  border: 1px solid#129e02 !important;
  background-color: #129e02 !important;
  color: #fff !important;
  font-weight: normal;
}

body
  .ui-panelmenu
  .ui-panelmenu-header:not(.ui-state-active)
  > a:hover
  .ui-panelmenu-icon,
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  color: #f5e98e !important;
}

.user-form {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
}

.user-form input[type="checkbox"] {
  margin-right: 9px;
  vertical-align: unset;
}

#left-panel .navbar {
  padding: 0;
}

body .ui-widget {
  font-size: 13px;
}

.table thead th {
  font-weight: 500;
}

#left-panel {
  background: #e4e4e4;
}

#left-panel li {
  display: block;
  width: 100%;
  border-bottom: 1px solid #000;
}

#left-panel li:last-child {
  border: none;
}

#left-panel li a {
  padding: 12px;
  display: block;
  color: #000;
  position: relative;
}

#left-panel li a:hover {
  background: #f8e794;
  text-decoration: none;
}

#left-panel li a i {
  padding-right: 12px;
}

#left-panel li .dropdown-toggle::after {
  right: 10px;
  position: absolute;
  top: 23px;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu {
  background: #353535;
  border: none;
  box-shadow: none;
  overflow-y: hidden;
  margin: 0;
  border-radius: 0;
  padding-left: 16px;
}

body .navbar .navbar-nav li.menu-item-has-children .sub-menu {
  max-height: 1000px;
  opacity: 1;
  position: static;
}

#left-panel li .sub-menu li {
  border: none;
}

#left-panel li .sub-menu li a {
  padding: 4px 12px;
  color: #fff;
}

.ui-panelmenu .ui-panelmenu-icon {
  float: right;
}

.left-panel .dropdown-toggle::after {
  border: none;
}

.navbar .navbar-nav li.menu-item-has-children > a::before {
  content: "";
  position: absolute;
  top: 23px;
  right: 14px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #000 #000 transparent transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s ease;
}

.navbar
  .navbar-nav
  li.menu-item-has-children
  > ul
  > li.menu-item-has-children
  > a::before {
  border-color: #fff #fff transparent transparent !important;
  top: 12px;
}

body .ui-dialog .ui-dialog-titlebar {
  border: 1px solid #1f1f1f !important;
  background: #1f1f1f !important;
  color: #f3c500 !important;
  box-shadow: 0 0 6px #f3c500;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
  color: #d6d6d6 !important;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: #d6d6d6 !important;
}

.add-btn {
  position: absolute;
  right: 0;
  top: 5px;
}

.form-control,
body .ui-inputtext {
  height: auto;
  padding: 3px 8px;
  line-height: normal;
}

.saperator {
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 19px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.user-form .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #938c55;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(147, 140, 85, 0.25);
}

.navbar .navbar-nav li.menu-item-has-children > a.btn-toggle::before,
.navbar .navbar-nav li.menu-item-has-children > ul > a.btn-toggle::before {
  -webkit-transform: rotate(133deg);
  transform: rotate(133deg);
}

body .ui-paginator {
  padding: 5px 0;
}

.user-area {
  padding-bottom: 18px;
  width: 335px;
  float: right;
}

.user-area:hover .user-menu {
  display: block;
}

.right-section .heading,
.heading2 {
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  font-size: 15px;
  margin-bottom: 12px;
  font-weight: bold;
}

.right-section .heading2 {
  padding-bottom: 0 !important;
}

.user-form h4 {
  text-align: center;
  font-size: 20px;
  margin: 10px 0 20px;
}

.user-form input {
  margin-bottom: 10px;
  font-size: 13px;
}

.step-form {
  text-align: center;
  display: table;
  margin: 0 auto;
  position: relative;
}

.step-form li::after {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: #d4d2d2;
  top: 17px;
  z-index: 0;
}

.step-form li.active div {
  color: red;
}

.step-form li.active::before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  height: 1px;
  top: 17px;
  background: red;
  z-index: 1;
  transition: all 0.4s;
}

.step-form li div {
  padding-top: 5px;
}

.step-form li {
  list-style: none;
  display: inline-block;
  padding: 8px 0 8px 80px;
  position: relative;
  z-index: 1;
  background: #f7f6f6;
  cursor: pointer;
}

.step-form li span {
  border: 2px solid #c5c1c1;
  position: relative;
  z-index: 2;
  padding: 8px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: table;
  margin: 0 auto;
  background: #f7f6f6;
}

.step-form li:last-child {
  padding-right: 80px;
}

.step-form li.active span {
  background: red;
  color: #fff;
  border-color: transparent;
}

@media (max-width: 1024px) {
  .btn {
    margin-bottom: 10px;
  }
  .redeem-form .btn {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .left-panel {
    display: none;
  }
  body #react-sidebar .sidebar-main {
    width: 96%;
  }
  /* .container {
        width:100%!important;
        margin-top: 10px!important;
    } */
  .right-panel {
    padding-right: 15px;
    padding-left: 31px;
    width: 100%;
    margin-left: 0;
  }
  .user-area {
    width: 100%;
  }
  .open .right-panel {
    width: 100%;
  }
  .open .left-panel {
    width: 100%;
    display: block;
    position: relative;
  }
  .open .sidebar-wraper {
    width: 100%;
  }
  .open .p-panelmenu-header .p-menuitem-text {
    font-size: 13px;
  }
  .open .p-toggleable-content {
    position: relative;
    right: 0;
    margin-top: 0;
  }
  .sidebar-wraper {
    height: auto;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 79px;
    background: #046bc4;
    padding: 12px;
    z-index: 9;
    padding-left: 36px;
  }
  .popup-modal {
    width: 65%;
  }
}

@media (max-width: 580px) {
  .popup-modal {
    width: 95%;
  }
  .box {
    margin: 10px 0;
  }
  .redeem-form .btn {
    margin: 0 auto;
  }
  .redeem-form .form-group {
    width: 100%;
  }
  .right-panel .heading h1 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .product-pic {
    width: 100%;
  }
  #menuToggle {
    float: right;
  }
  .row1 {
    margin-left: 0;
    margin-right: 0;
  }
  .logo-section {
    width: 100%;
    text-align: center;
  }
  .right-section {
    width: 100%;
    text-align: center;
    padding: 0px 15px 0 20px;
  }
  .right-panel {
    margin-top: 18px;
  }
  .header-top {
    position: relative;
    left: 0;
  }
  .sidebar-wraper {
    top: 3px;
  }
  .footer {
    position: relative;
  }
  .right-panel {
    padding-right: 23px;
    padding-left: 23px;
  }
}

.tblAuto .p-datatable-wrapper > table {
  border-collapse: collapse !important;
  width: 100% !important;
  table-layout: auto !important;
}

.tblAuto .p-datatable-thead > tr > th:last-child {
  width: 120px !important;
}

body .p-paginator .p-dropdown .p-dropdown-label,
body .p-paginator .p-dropdown .p-dropdown-trigger {
  padding-bottom: 1px !important;
  color: #848484 !important;
}

/* .container {
    width: 900px;
    margin: 100px auto;
} */

.progressbar {
  counter-reset: step;
}

.progressbar li {
  list-style-type: none;
  width: 10%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: green;
}

.progressbar li.active:before {
  border-color: #55b776;
}

.progressbar li.active + li:after {
  background-color: #55b776;
}
