/* PA-STYLE */
.row.no-gutterss {
  margin-bottom: 1.5rem;
}
.pa-table .table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
  text-align: center;
}
.table-head {
  background: #ddd;
  margin: 0px;
  padding: 20px 10px;
}
.success-icon {
  width: 20%;
}
.table-data {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding: 30px;
  border: 1px solid #ddd;
  /* border-bottom: 1px solid #ddd; */
}
.pa-table table {
  width: 90%;
  margin-bottom: 1rem;
  color: #212529;
}
.pa-content {
  width: 50%;
  margin: 0 auto;
}
.mt5 {
  margin-top: 2.5rem !important;
}
.mb10 {
  margin-bottom: 6rem !important;
}
.col-md-2.col-md-offset-7 {
  text-align: right;
}
.desinged-by {
  color: #c0e8f7;
}
.text-3xl {
  font-size: 2.875rem;
  line-height: 2.25rem;
}
.text-p {
  font-size: 1.5rem;
  line-height: 2.25rem;
}
.uppercase {
  text-transform: uppercase;
}
.font-bold {
  font-weight: 700;
}
.login hr {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid #c1bbbb;
}
.change-pass {
  margin-bottom: 0 !important;
  font-size: 20px;
  text-align: center !important;
}
/* box-style */
.box-space {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
}
.chart-sec {
  width: 100% !important;
  padding: 0 0px !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
  font-size: 18px;
}
/* 
.box-side1:hover{background-color: #e6e8e9;}
.box-side2:hover{background-color: #e6e8e9;}
.box-side3:hover{background-color: #e6e8e9;}
.box-side4:hover{background-color: #c7efef;} */
.box1 {
  position: absolute;
  right: 18px;
}

.box-side1 {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadw);
  /* border-bottom: 5px solid #069775 !important;
    border-radius: 10px;
    background-image: linear-gradient(#a7f3d0, #d1fae5);
    padding: 15px;
    */
}
.box-side2 {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadw);
  border-radius: 10px;
  /* background-image: linear-gradient(#fbcfe8, #fce7f3);
    padding: 15px;
   border-bottom:5px solid #f54ea2 !important; */
}
.box-side3 {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadw);
  border-radius: 10px;
  /* background-image: linear-gradient(#fde68a, #fef3c7);
    padding: 15px;
   border-bottom:5px solid #f7965d  !important; */
}

.bg-gradient-ohhappiness {
  background: #0a723d;
}

.bg-gradient-scooter {
  background: #0a723d;
}

.bg-gradient-bloody {
  background: #0a723d;
}

.widgets-icons-2 {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #ededed; */
  font-size: 30px;
  border-radius: 10px;
}
.dash .box1 {
  position: absolute;
  right: -10px;
}
.radius-10 {
  border-radius: 10px;
}
.row.chart-sec {
  background: #fafafa;
  /* padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
}
.dashpage-bg {
  background: #f3f3f4;
  background-size: cover;
  height: 100%;
  padding-bottom: 5rem;
  width: 100%;
  margin-top: 2rem;
  overflow-y: auto;
}
div#reactgooglegraph-1 {
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
}
div#carousel {
  margin-top: -40px !important;
}
.mybox {
  text-align: center;
  background: #c42f02;
  padding: 1rem;
  font-size: 2rem;
  color: #fff;
}
.mybox1 {
  text-align: center;
  background: #e9910e;
  padding: 1rem;
  font-size: 2rem;
  color: #fff;
}
.mybox2 {
  text-align: center;
  background: #1e67d0;
  padding: 1rem;
  font-size: 2rem;
  color: #fff;
}
.card.card-outline-secondary.changpass {
  width: 100%;
}
section.changepass {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
}
/* Responsiveness */
@media (max-width: 768px) {
  .dashpage-bg {
    background: #f3f3f4;
    background-size: cover;
    height: 100%;
    padding-bottom: 10rem;
    width: 100%;
    margin-top: 1rem;
    overflow-y: auto;
  }
  .pa-content {
    width: 98%;
    margin: 0 auto;
  }
  .block {
    background: #fff;
    padding: 10px !important;
    border-radius: 5px;
  }
  .table-data {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    /* border-bottom: 1px solid #ddd; */
  }
  .mt5 {
    margin-top: 0rem !important;
  }
}
.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.feature {
  text-align: center;
  color: #273896;
  font-weight: 600;
  font-size: 2rem;
}

/* slider */
